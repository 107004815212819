.contact {
    width: 30px;
    height: 30px;
    margin: 5px 15px;
    align-self: center;
    box-shadow: 1px 2px 3px blanchedalmond;
    border-radius: 5px;
}


.contactCon {
    margin-bottom: 50px;
    margin-top: 40px;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.contactHeader {
    margin-bottom: 50px;
}

.socialIcons {
    opacity: 0.7;
}



@media only screen and (max-width: 500px) {
.contact {
    width: 25px;
    height: 25px;
    margin: 6px;
}
.contactCon {
    margin-bottom: 40px;
    margin-top: 40px;
}

}
