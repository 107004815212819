.profilePhoto {
    height: 12rem;
    width: 11rem;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 1px 2px 3px blanchedalmond;
    transition: 0.3s;
    align-self: center;
    opacity: 0.6;
}

.profile {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
    position: relative;
    margin-top: 1px;
}

.profilePhoto:hover {
    transform: scale(1.1);
    opacity: 0.9;
}

.text1 {
    border-radius: 5px;
    width: 55%;
    padding: 3.5%;
    align-self: center;
    text-align: left;
    line-height: 2em;
    font-size: 18px;
    margin: 4%;
    border: 1px solid #64ffda;
    border-radius: 5px;
}

