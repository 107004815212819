.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

hr {
    width: 80%;
    opacity: 0.3;
}

.footer {
    display: flex;
    opacity: 0.5;
    flex-direction: column;
    width: 80%;
}

.domains {
    align-self: center;
    width: 70vw;
    text-align: center;
}

.domains a {
    padding: 3px;
}

/* 
   ==================================
	Mobile Styles: 0px to 480px
   ==================================
*/
@media only screen and (max-width: 480px) {
    .domains {
        text-align: left;
        width: 70%;
    }
}