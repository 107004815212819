  .navbar {
      padding: 0;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      width: 100%;
      box-shadow: 1px 2px 3px black;
      position: relative;
  }

  .header {
      padding: 5px;
      margin: 10px 8px 10px 5%;
      border: 1px solid #64ffda;
      color: white;
      width: 6%;
      min-width: 80px;
      max-width: 100px;
      font-weight: bold;
      border-radius: 5px;
      opacity: 0.8;
      transition: 0.3s;
      cursor: default;
      font-size: 18px;
      box-shadow: 1px 3px 5px #64ffda;
      text-align: center;
      align-self: flex-start;
  }

  .header:hover {
      opacity: 1;
      transform: scale(1.1);
  }

  .download{
    padding: 5px;
    margin: 10px 8px 10px 60%;
    background-color: rgb(0, 0, 0);
    color: white;
    width: 6%;
    min-width: 100px;
    max-width: 140px;
    font-weight: bold;
    border-radius: 5px;
    opacity: 0.8;
    transition: 0.3s;
    cursor: default;
    font-size: 16px;
    box-shadow: 1px 3px 5px rgba(8, 8, 36, 0.541);
    align-self: flex-end;
  }


  .download:hover {
    opacity: 1;
    transform: scale(1.1);
}

a{
    text-decoration: none;
    color:white;
}
/* 
   ==================================
	Mobile Styles: 0px to 480px
   ==================================
*/
  @media only screen and (max-width: 480px) {
      .header {
          margin-left: 15%;
	  font-size: 16px;
	  width: 60px;
	  margin: 5px 20px 5px;
      }
      .download{
          margin-left: 20%;
	  font-size:15px;
      }
  }


/*
   ==================================
	Tablet Styles: 481px and greater
   ==================================
*/
@media only screen and (min-width: 481px) and (max-width: 1025px){
    .header {
        margin-left: 15%;
    }
    .download{
        margin-left: 40%;
    }
}
