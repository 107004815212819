.App {
  text-align: center;

}

html {
  width: 100%;
  background-color: #0a192f;
  color: #8892B0;
  font-family: monospace;
}

h1 {
  color: #64ffda;
  font-family: monospace;
}

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

/* 
  ==================================
	  Mobile Styles: 0px to 480px
  ==================================
*/
@media only screen and (max-width: 480px) {
  .text1 {
      font-size: 16px;
padding: 8px;
      width: 80%;
      margin-bottom: 30px;
  }


.techPage {
    width: 98%;
    max-width: 480px;
    min-width: 320px;
}
}


/* 
  ==================================
    Tablet Styles: 480px to 1280px
  ==================================
*/
@media only screen 
and (min-device-width : 481px) 
and (max-device-width : 1280px)  {
  .techPage{
    width: 70%;
    max-width: 700px;
    min-width: 310px;
  }
}