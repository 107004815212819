.cardImage {
  width: 280px;
  height: 170px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.698);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;
}

.cardImage:hover {
  opacity: 1;
  transform: scale(1.1);
}

.code {
  width: 35px;
  height: 30px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.527);
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #64ffdbcb;
  opacity: 0.7;
}

.code:hover {
  transform: scale(1.3);
}


.capContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3%;
}

.Card {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 150px;
  margin: 10px;
  margin-bottom: 70px;
}

/* 
  ==================================
	Mobile Styles: 0px to 480px
  ==================================
*/
@media only screen and (max-width: 480px) {
  .logo {
    margin: 20px;
  }

  .techPage {
    width: 90%;
  }
}