.techPage {
    display: inline-block;
    align-items: center;
    width: 70%;
    max-width: 800px;
    margin: 1%;
    margin-bottom: 100px;
}

.techLogo {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1%;
}

figure {
    display: inline;
}

.logo {
    width: 35px;
    height: 35px;
    margin: 5px 25px;
    align-self: center;
    background-color: white;
    box-shadow: 1px 2px 3px blanchedalmond;
    border-radius: 5px;
    transition: 0.3s;
    opacity: 0.7;
}

.logo:hover {
    transform: scale(1.5);
    opacity: 0.9;
}

figcaption {
    cursor: default;
}


