.timeline{
    width: 450px;

}
.timelineContainer{
    display: flex;
    justify-content: center;
}


