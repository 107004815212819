.project {
    width: 48%;
    max-width: 800px;
    align-items: center;
    margin: auto;
    margin-bottom: 100px;
}

.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

h1 {
    margin-bottom: 38px;
    font-size: 27px;
    cursor: default;
}



/* 
  ==================================
   Tablet Styles: 480px to 1400px
  ==================================
*/
@media only screen 
and (min-device-width : 481px) 
and (max-device-width : 1400px)  {
  .project {
    width: 68%;
}
}